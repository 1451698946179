<!--
 * @Descripttion:
 * @version:
 * @Author: 李波
 * @Date: 2020-01-09 17:12:51
 * @LastEditors  : 黄孝娟
 * @LastEditTime : 2020-03-05 17:47:48
 -->
<template>
  <div class="content">
    <div class="productcontent">
      <div id="jumpshow"></div>
    </div>
  </div>
</template>

<script>
import { selectcpzl } from '@/api/agent/agent/index'
import {
  DoRecord
} from '@/api/abt/customerOperation/common/index'
import { getStorage } from '@/lib/util'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import initWebSocket from '@/mixins/websock'
export default {
  mixins: [initWebSocket],
  data () {
    return {
      websock: null,
      user: '',
      ossurl: '',
      // jumpUrl: '',
      prodname: '',
      pdesc: '',
      sno: '',
      empno: '',
      rytype: '',
      suserid: '',
      iconhttp: '',
      // libnoinfo:''
      prodcp: '',
      prodcode: ''
    }
  },
  components: {

  },
  created () {
    this.user = getStorage('u_s', {})
    this.ossurl = getStorage('ossurl', '')
    this.sno = this.$route.query.prodcode
    this.empno = this.$route.query.empno ? this.$route.query.empno : this.user.empno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    this.prodcode = this.$route.query.prodcode
    this.information()
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.jump()
    // })

  },
  destroyed: function () {
    this.websocketclose() // 页面销毁时关闭websocket连接
  },
  methods: {
    information () {
      selectcpzl({ prodcode: this.prodcode, qdbk: '2' }).then(res => {
        this.prodcp = res.data.data.showmsg
        this.jump()
      })
    },
    jump () {
      window.document.title = this.prodcp.prodname
      $('#jumpshow').load(this.ossurl + this.prodcp.showhttp)
      let shareurl = `${config.redirect_uri}/productShowNhwx?prodcode=${this.prodcode}&recordtype=7&suserid=${this.user.userid}&empno=${this.empno}&rytype=${this.rytype}&sharetype=00&isshare=true`

      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        sno: this.prodcode,
        rytype: this.rytype,
        otype: '2',
        btagcode: '7'
      }
      let path = ''
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // ios
        if (this.$route.query.frompage == 'source') {
          path = config.redirect_uri + '/source'
        } else {
          path = config.redirect_uri
        }
      } else {
        // android
        path = window.location.href
      }
      wechatshare(
        this.prodcp.prodname,
        this.prodcp.pdesc,
        this.ossurl + this.prodcp.iconhttp,
        shareurl,
        path,
        fxstr
      )
      this.behaviorRecord()
    },
    // 行为记录
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        sno: this.prodcode,
        otype: '1',
        btagcode: '7',
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
        // alert(id)
      })
    }
  }
}
</script>
<style scoped lang="stylus">
.productcontent {
  padding: 1rem;
}
</style>
